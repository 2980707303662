<!--
 * @Descripttion:
 * @version:
 * @Author: 张圳
 * @Date: 2023-11-19 15:53:32
 * @LastEditors: Andy
 * @LastEditTime: 2023-11-19 16:34:03
-->
<template>
  <div class="container-warp">
    <back-title>课程详情</back-title>
    <div class="name-title">
      <com-title>课程名称111</com-title>
      <div style="margin-left: 12px">课程编号 HR001564</div>
    </div>
    <div class="teacher-box">
      <div>带教老师</div>
      <el-select v-model="teacher" style="width: 274px;margin:0 32px 0 16px" placeholder="请选择">
        <el-option
          v-for="item in teacherList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button type="primary" plain>开始学习</el-button>
    </div>
    <div style="margin-top:24px;">
      <com-title>课程描述</com-title>
      <div class="describe-text">计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述计划描述</div>
    </div>
    <div style="margin-top:24px;">
      <com-title>课程介绍内容</com-title>
      <div class="describe-text">课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容课程介绍内容</div>
    </div>
  </div>
</template>

<script>
import backTitle from '@/components/backTitle/index.vue'
import comTitle from '@/components/comTitle/index.vue'
export default {
  components: {
    backTitle,
    comTitle
  },
  data () {
    return {
      teacher: '',
      teacherList: [
        {
          value: '选项1',
          label: '黄金糕'
        },
        {
          value: '选项2',
          label: '双皮奶'
        },
        {
          value: '选项3',
          label: '蚵仔煎'
        },
        {
          value: '选项4',
          label: '龙须面'
        },
        {
          value: '选项5',
          label: '北京烤鸭'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.container-warp {
  padding: 16px 24px;
  min-height: 900px;
  background-color: #ffffff;
  border-radius: 4px;
}
/deep/.title-box {
  margin-bottom: 16px;
}
.name-title {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #666666;
}
.teacher-box {
  display: flex;
  align-items: center;
  margin-top: 16px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
}
.describe-text{
  margin-top: 8px;
  font-size: 14px;
  color: #4D4D4D;
}
</style>
